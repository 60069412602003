import { GenericInput, getGenericAction } from '@msdyn365-commerce-modules/retail-actions';
import { createObservableDataAction, IAction, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { SearchState } from './search-state';

export function createSearchStateInput(result: SearchState): GenericInput<SearchState> {
    return new GenericInput<SearchState>('searchState', result, 'SearchState');
}

const createsearchStateInputInternal = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return createSearchStateInput({ searchText: '' });
};

export const getGenericActionDataAction = createObservableDataAction({
    action: <IAction<SearchState>>getGenericAction,
    input: createsearchStateInputInternal
});

export default getGenericActionDataAction;